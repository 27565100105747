import { delCookie } from "./utils";
import { isClient } from "./envHelper";
import TokenCookie, {TokenKey} from "./TokenCookie";

export const broadcastChannel = (action, key, value) => {
  if(isClient){
    if (action === 'setItem') {
      // Handle setItem
      localStorage.setItem(key, value);
    } else if (action === 'removeItem') {
      // Handle removeItem
      localStorage.removeItem(key);
    }
    // console.log('00001 message1', action, key, value)
    const channel = new BroadcastChannel('localStorageChannel');
    channel.postMessage({ action, key, value });
  }
};

export const syncLocalStorage = () => {
  if(isClient){
    const channel = new BroadcastChannel('localStorageChannel');
    const keysToReset = [TokenKey.R_TOKEN];
    const prevSessionValue = TokenCookie.get(TokenKey.R_TOKEN);
    let resetStatus = {};
    channel.onmessage = function (event) {
      const { action, key, value } = event.data;
      // if (action === 'setItem') {
      //   // Handle setItem
      //   sessionStorage.setItem(key, value);
      // } else if (action === 'removeItem') {
      //   // Handle removeItem
      //   sessionStorage.removeItem(key);
      // }
      // console.log('0000 path', action, key, value)
      // // Check if the removed key is one of the keysToReset
      if (keysToReset.includes(key)) {
        resetStatus[key] = true;
        // Check if all keys have been reset
        if (keysToReset.every(key => resetStatus[key])) {
          // Reload the page only once when all keys are reset
          const currentPath = location.pathname;
          const requireLoginPath = ['/copytrading/trader'];
          if(key === keysToReset[0])
          {
            // 若有登入，則不重新整理
            if(prevSessionValue !== value){
              if(!value || value.length === 0) {
                window.$nuxt.$store.commit('CLEAR_USER');
                window.$nuxt.$store.commit('SET_TRADER_NEW_TIPS', false);
                window.$nuxt.$store.commit('SET_MIX_TRADER_NEW_TIPS', false);
                window.$nuxt.$store.commit('future/UPDATE_LOGIN_STATUS', false);
                delCookie('bt_rtoken');
                // 需要登入才能瀏覽的頁面，若沒有登入，跳轉到登入頁面
                // requireLoginPath.forEach(path => {
                //   if(currentPath.includes(path)) {
                //     // console.log('0000 path', currentPath, path)
                //     location.href = currentPath.replace(path, '/login')
                //   }
                // });
              }else{
                window.$nuxt.$store.commit('future/UPDATE_LOGIN_STATUS', true);
              }
            }
          }
        }
      }
    };
  }
}
