// 本机网络IP匹配正则
export const localIpRegex = /^(127\.0\.0\.1|192\.168\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)|localhost)$/;

export const isProduction = process.env.NODE_ENV === 'production' && process.env.BASE !== 'rc';
export const isRC = process.env.BASE === 'rc';
export const isStaging = process.env.NODE_ENV === 'development' && process.env.BASE === 'staging';
export const isTest =
  process.env.NODE_ENV === 'development' && (process.env.BASE === 'test' || process.env.BASE === 'test3');
export const isDev = process.env.NODE_ENV === 'development' || process.env.BASE === 'development' || process.env.MODE === 'dev';
export const isClient = process.client && typeof window !== 'undefined';
export const TVDebugMode = process.env.TV_DEBUG || false;
export const isLocal = isClient && localIpRegex.test(window.location.hostname);
// export const rToken = isClient
//   ? (window.sessionStorage?.getItem('bt_rToken') || getCookie('bt_rToken_client') || getCookie('bt_rtoken'))
//   : null;
// export const isLogin =
//   isClient && (window.sessionStorage?.getItem('bt_rToken')) || getCookie('bt_rToken_client') || getCookie('bt_rtoken');
// export const accessToken = isClient
//   ? window.sessionStorage?.getItem('bt_ccToken') || window.localStorage?.getItem('bt_rToken')
//   : null;
