
import Cookies from 'js-cookie'
import {getCookieDomain} from "./utils";

export const TokenCookieKey = `WEEX_TOKEN_COOKIE_${process.env.BASE.toUpperCase()}`

const TokenKey = {
    // ccToken
    CC_TOKEN: "bt_ccToken",
    // ccToken过期时间
    CC_TOKEN_EXPIRE: "bt_ccToken_expire",
    // rToken
    R_TOKEN: "bt_rToken",
    //refresh_token
    REFRESH_TOKEN: 'refresh_token',
    // refresh_token过期时间
    REFRESH_TOKEN_EXPIRE: "refresh_token_expire",
}

const TokenCookie = {
    ready: false,

    data: {
      [TokenKey.CC_TOKEN]: '',
      [TokenKey.CC_TOKEN_EXPIRE]: 0,
      [TokenKey.R_TOKEN]: '',
      [TokenKey.REFRESH_TOKEN]: '',
      [TokenKey.REFRESH_TOKEN_EXPIRE]: 0,
    },
    /**
     * 从cookie中将缓存加载到本实例
     */
    init() {
        try {
            const localData = Cookies.get(TokenCookieKey);
            const local = localData ? JSON.parse(localData) : ''
            if (local) {
                // 防止数据缺失
                Object.assign(this.data, local)
            }
        } catch (e) {
            console.error('本地缓存数据被破坏，使用初始值', e)
        } finally {
            this.ready = true
        }
    },
    get(key) {
        if (!this.ready) {
            this.init()
        }
        return this.data[key]
    },
    set(key, value) {
        if (!key) {
            return;
        }
        if (!this.ready) {
            this.init()
        }
        if (this.data[key] === value) {
            return;
        }
        this.data[key] = value;
        try {
            const domain = getCookieDomain();
            Cookies.set(TokenCookieKey, JSON.stringify(this.data), { expires: 365, domain, secure: true, sameSite: 'Lax' })
        } catch (e) {
            console.error('Local cookie Error:', e)
        }
    },
    remove(key) {
      if (!key) {
        return;
      }
      if (!this.ready) {
        this.init()
      }
      if (this.data[key] === '') {
        return;
      }
      this.data[key] = '';
      try {
        const domain = getCookieDomain();
        Cookies.set(TokenCookieKey, JSON.stringify(this.data), { expires: 365, domain, secure: true, sameSite: 'Lax' })
      } catch (e) {
        console.error('Local cookie Error:', e)
      }
    }
}

export { TokenKey }
export default TokenCookie
