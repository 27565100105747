
//获取设置指纹 及设备信息

import Fingerprint2 from './fingerprint2.js'
import UAParser from './ua-parser.js'


export const getParser=()=>{
   let parser=new UAParser(navigator.userAgent);
   return {
     os:parser.getOS(),
     browser:parser.getBrowser()
   }
}

function calFinger(value){
  return Fingerprint2.x64hash128(value, 31)
}


export const getFinger=(cb)=>{
  if (window.requestIdleCallback) {
    requestIdleCallback(()=> {
        Fingerprint2.get( (components)=> {
          var values = components.map(function (component) { return component.value })
         var murmur = calFinger(values.join(''));
         if(cb){
          cb(murmur)
        }
        })
    })
    } else {
    setTimeout( ()=> {
        Fingerprint2.get( (components)=> {
         // console.log(components) // an array of components: {key: ..., value: ...}
         var values = components.map(function (component) { return component.value })
         var murmur = calFinger(values.join(''));
         if(cb){
           cb(murmur)
         }
        })
    }, 500)
    }

}


