import Digest from './md5.min.js'
import { encode } from './base64.min.js'
import { getCurrentTime } from '../server/time'
import CryptoJS from 'crypto-js';

function selfmd5(str) {
  if (str) {
    let m = Digest.MD5(); // 此处不能使用链式调用。
    return encode(m.digest(str));
  }
  return str;
}

export function md5(str) {
  return selfmd5(str);
}

export const cryptoObj = (str, callback) => {
  if (str) {
    let t = Date.now();
    let temp = {
      cryptoStr: selfmd5(str),
      currTime: t
    }
    getCurrentTime().then(res => {
      if (res.code === '00000' && res.data.t) {
        temp.currTime = res.data.t;
        temp.cryptoStr = selfmd5(str);
        callback && callback(temp);
      } else {
        callback && callback(temp);
      }
    }).catch(err => {
      callback && callback(temp);
      console.error(err);
    });
  } else {
    console.error('不能输入空字符串');
  }
}

export const cryptoObjTime = (str, callback) => {
  if (str) {
    let t = Date.now();
    let temp = {
      cryptoStr: selfmd5(selfmd5(str) + t),
      currTime: t
    }
    getCurrentTime().then(res => {
      if (res.code == '00000' && res.data.t) {
        temp.currTime = res.data.t;
        temp.cryptoStr = selfmd5(selfmd5(str) + res.data.t),
        callback && callback(temp);
      } else {
        callback && callback(temp);
      }
    }).catch(err => {
      callback && callback(temp);
      console.error(err);
    });
  } else {
    console.error('不能输入空字符串');
  }
}

/**
 * AES-256-CBC对称解密
 * @param textBase64 {string} 要解密的密文，Base64格式
 * @param secretKey {string} 密钥，43位随机大小写与数字
 * @returns {string} 解密后的明文
 */
export const AES_CBC_DECRYPT = (textBase64, secretKey = null, iv = null) => {
  const _bytes = CryptoJS.AES.decrypt(
    textBase64,
    CryptoJS.enc.Utf8.parse(secretKey || process.env.AES_KEY || ''),
    { iv: CryptoJS.enc.Utf8.parse(iv || process.env.AES_IV || '') }
  )
  return _bytes.toString(CryptoJS.enc.Utf8);
}
