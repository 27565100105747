import {getAPICNDomains} from "./s3Helper";
import axios from "axios";
import {minBy} from "lodash";
import {isOversea} from "./urlHelper";
import {isRC, isStaging, isTest} from "./envHelper";

/**
 * 网络线路测速类
 */
export default class DetectSpeed {
    _store = null; // 当前应用store实例，实例化时候初始化赋值
    // 当前选中线路
    _domain = {};
    get domain() {
        return this._domain
    }
    set domain(value) {
        // 当前线路保存store
        this._store.commit('SET_DOMAIN', value)
        this._domain = value;
    }
    // 所有线路
    _domains = [];
    get domains() {
        return this._domains
    }
    set domains(value) {
        // 最新的所有线路保存store
        this._store.commit('SET_DOMAINS', value)
        this._domains = value;
    }

    constructor(store) {
        this._store = store;
        // 实例化时候初始化
        this.init()
    }

    /**
     * 初始化
     * @returns {Promise<void>}
     */
    async init() {
        // 判断是否海外用户，获取域名列表
        const domains = isTest || isStaging ? ['weex.tech'] : !isOversea() ?  await getAPICNDomains() : ['weex.com'];
        // 如果已经有线路列表则不用再初始化
        if(this.domains.length === 0){
            this.domains = domains.map((e, i) => ({domain: e, speed: 9999, index: i+1}));
            await this.detect()
        }
        // 取时延最小的线路
        this.domain = minBy(this.domains, o => o.speed );
    }

    /**
     * 所有线路测速
     * @returns {Promise<void>}
     */
    async detect() {
        let allRequest = this.domains.map(e => this.detectDomain(e));
        this.domains = await Promise.all(allRequest)
    }

    /**
     * 单条线路测速
     * @param url
     * @returns {Promise<unknown>}
     */
    detectDomain(url) {
        return new Promise(resolve => {
            // 记录开始时间
            const startTime = Date.now();
            axios.get(`https://${process.env.API_PUBLIC_PREFIX}.${url.domain}/v1/if/health`, {timeout: 20*1000})
                .then(_ => {
                    // 记录返回时间
                    const endTime = Date.now();
                    // 计算时延保存到线路
                    url.speed = endTime - startTime;
                    resolve(url);
                })
                .catch(_ =>{
                    // 测速异常则时延默认为最大值
                    url.speed = 9999;
                    resolve(url)
                })
        })
    }
}
