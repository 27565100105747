/* web对应的app页面函数方法 */
/**
 * @path web的页面路径
 * @param {String} oldAppObj app端版本小于'3.3.0'的方法名称
 * @param {String} newAppObj app端需要的参数
 */
export default [
  {// 去首页
    path: '',
    oldAppObj: {
      method: 'goRootController',
      param: 'home'
    }
  },
  {
    // 登录
    path: 'login',
    oldAppObj: {
      method: 'goLogin',
      param: 'login'
    },
    newAppObj: {
      func: 'gotoLogin'
    }
  },
  {
    // 注册
    path: 'register',
    oldAppObj: {
      method: 'gotoRegPage',
      param:null
    }
  },
  {
    // 资产页面买币
    path: 'asset/recharge',
    oldAppObj: {
      method: 'goRootController',
      param: 'asset'
    },
    newAppObj: {
      func: 'gotoAssetRecharge',
      coinId: ''
    }
  },
  {
    // 新手活动分享海报
    path: 'share',
    oldAppObj: {
      method: 'gotoShare',
      param:null
    }
  },
  {
    // 跳到KYC
    path: 'kyc',
    oldAppObj: {
      method: 'goRealName',
      param: ''
    }
  },
  {
    // 跳到划转页面
    path: 'asset/transfer',
    oldAppObj: {
      method: 'gotoTransfer',
      param:null
    }
  },
  {
    // 跳到新合约交易页面
    path: 'swapnew/cmt_btcusdt',
    oldAppObj: {
      method: 'goRootController',
      param: 'newContract'
    }
  }
];
