import {locales, SUPPORTED_THEME} from 'bgconfig';

export const LocalStorageKey = 'WEEX_LOCAL_CACHE';

export const CacheKey = {
  CHANGE_MODE_WITHOUT_DIALOG: 'change_mode_without_dialog',
  GLOBAL_LOCALE: 'global_locale',
  GLOBAL_THEME: 'global_theme',
  GLOBAL_LAYOUT: 'global_layout',
  TERMINAL_CODE: 'terminal_code',
  KLINE_INTERVAL: 'kline_interval',
  SPOT_LAYOUT: 'spot_layout',
  GLOBAL_THEME_NEW: 'global_theme_new'
};

const LocalCache = {
  ready: false,
  /**
   * 默认的数值
   */
  data: {
    version: '1.1', // 使用该字段将用户缓存的数据失效
    [CacheKey.CHANGE_MODE_WITHOUT_DIALOG]: false, // 切换逐仓全仓不需要弹窗
    [CacheKey.GLOBAL_LOCALE]: locales[0],
    [CacheKey.GLOBAL_THEME]: SUPPORTED_THEME[0],
    [CacheKey.GLOBAL_LAYOUT]: 'right',
    [CacheKey.TERMINAL_CODE]: '', // 设备指纹，请求用户信息等接口时使用
    [CacheKey.KLINE_INTERVAL]: 1, // 默认 1m普通K线
    [CacheKey.SPOT_LAYOUT]: 'center', // 默认币币为居中布局
  },
  /**
   * 从localStorage中将缓存加载到本实例
   */
  init() {
    try {
      const local = JSON.parse(localStorage.getItem(LocalStorageKey));
      if (local) {
        // 检查版本
        if (local.version && local.version === this.data.version) {
          // 防止数据缺失
          Object.assign(this.data, local);
        } else {
          // 刷新用户端保存的内容
          localStorage.setItem(LocalStorageKey, JSON.stringify(this.data));
        }
      }
    } catch (e) {
      console.error('本地缓存数据被破坏，使用初始值');
    } finally {
      this.ready = true;
    }
  },
  get<T>(key: CacheKey): T {
    if (!this.ready) {
      this.init();
    }
    return this.data[key];
  },
  set(key: CacheKey, value: any) {
    if (!this.ready) {
      this.init();
    }
    let oldValue = this.data[key];
    if (oldValue === value) return;
    let newCache = Object.assign({}, this.data, {[key]: value});
    try {
      localStorage.setItem(LocalStorageKey, JSON.stringify(newCache));
      this.data = newCache;
    } catch (e) {
      console.error('Local Cache Error:', e);
    }
  },
};

export default LocalCache;
