// @flow
// $flow-disable-line
import { inflate } from 'pako';
import { getTokenData, getStreamWSUrl } from '~/utils/urlHelper';
import { getWSTimoutMilliSecs, removeTailRecursive, getCurrentTime } from '~/utils/utils';
import { MessageBox } from 'element-ui';
import { CONNECTION_ERROR_CODE } from '../src/WebsocketHero2.0/event';
import sEvent from "./sensorsEvent";
import LocalCookie, {CookieKey} from "./localCookie";

let configPath = require('configPath');

type SocketScore = {
  name: string, // 通道名称
  url: string, // 通道url
  score: number, // 得分
  errorCount: number, // 错误次数
  avgSpeed: number, // 平均流速
  time: number, // 最近使用时间
  connectTime: number, // 连接用时
  messageLength: number, // 数据长度
  messageTime: number // 数据用时间
};

let reconnectCount = 0;

export class WebsocketProvider {
  // websocket连接地址
  _websocketUrl: string;
  // 持有websocket连接
  _connection: WebSocket;
  // 执行队列，当websocket正在连接时如果有sendMsg的需求，会在该数组中暂存，open回调中将按序将消息发出
  _executeQueue: any[];
  // 监听器容器，key是action名称，value是监听函数的数组，同一个频道可能有多个监听操作
  _listeners: { [string]: Function[] };
  // 心跳计时器
  //_heartbeat: IntervalID; // 心跳重连的定时器ID
  // 心跳重试计数器
  _heartbeatCounter: number;
  // 心跳响应时间戳
  _heartbeatTime: number;
  // 自定义的需要在指定生命周期中执行的回调函数
  _customLifeCycleCallbacks: { [string]: Function[] };
  // 该map用于记录已订阅的状态
  _subscribeActionRecords: { [string]: string };
  // 断开连接的标志位 0-正常关闭，需要ON_CLOSE钩子执行， 1-重连关闭，不需要执行
  _closeFlag: number;
  // 重连标志位
  _reconnectFlag: boolean;
  // 重连计时器
  _reconnectTimer: TimeoutID | null;
  // 過時计时秒數
  _connectTimeoutSec: number;
  // 是否啟用debug打印
  _option: any;
  // 是否启用压缩
  socketCompress: boolean;
  // 是否需要切换通道
  needReport: boolean;
  currentSocketIndex: number; // 当前使用的socket在backup中的索引
  socketScoreBoard: SocketScore[]; // 计分板
  timeBoard: { [string]: number }; // 计时器
  messageLength: number; // 记录接受数据字符串的大小
  messageBegin: boolean; // 消息统计开始
  messageEnd: boolean; // 消息统计结束
  _reconnectBeginTime: number; // 重连开始时间
  _reconnectEndTime: number; // 重连结束时间
  showErr1007: boolean;
  _sensorsReportInterval: number;   // 神策上报时间间隔
  _heartbeatTimes: [number];  // 心跳时间间隔记录
  sensorsReport: TimeoutID | null;  // 心跳定时器

  /**
   * 构造provider
   * @param url 必要！连接地址
   */
  constructor(url: string) {
    // 初始化实例的各项参数
    this._websocketUrl = url;
    this.socketCompress = !!process.env.WEBSOCKET_COMPRESSION
    // this.socketCompress = configPath.bbSocketCompress;
    this._listeners = {};
    this._executeQueue = [];
    this._customLifeCycleCallbacks = {};
    this._option = {
      debug: true
    };
    this.needReport = configPath.bbSocketReport;
    // 初始化钩子容器
    for (let lifeCycle in WEBSOCKET_PROVIDER_ENUM) {
      if (WEBSOCKET_PROVIDER_ENUM.hasOwnProperty(lifeCycle)) {
        this._customLifeCycleCallbacks[lifeCycle] = [];
      }
    }
    // 初始化记录器
    this._subscribeActionRecords = {};
    this._connectTimeoutSec = 5000;
    this._sensorsReportInterval = 60 * 1000;
    this._heartbeatTimes = [];
    this._initStatisticData()
    if (this.needReport) {
      // 初始化socket计分板
      this.socketScoreBoard = JSON.parse(localStorage.getItem('weex::bbSocket::score') || '[]');
      this.currentSocketIndex = -1;
    }
  }

  /**
   * 初始化统计用数据
   * @private
   */
  _initStatisticData() {
    this.timeBoard = {};
    this.messageLength = 0;
    this.messageBegin = this.messageEnd = false;
  }
  /**
   * 内部使用，生成连接字符串
   * @returns {string}
   * @private
   */
  async _getUrl() {
    return await getStreamWSUrl();
  }

  /**
   * 通知
   * @param channel 需要通知的频道
   * @param data 需要通知的数据
   * @private
   */
  _notify(channel: string, data: {}) {
    // 这里的data是原始的数据，有订阅时给定的函数自行处理成功失败的逻辑
    if (this._listeners[channel]) {
      this._listeners[channel].forEach(callback => {
        if (typeof callback === 'function') {
          callback(data);
        }
      });
    }
  }

  _clearSensorsReportTimer() {
      this.sensorsReport && clearInterval(this.sensorsReport);
      if(this._heartbeatTimes.length > 0){
          sEvent('ws_response_time', this._websocketUrl, {ext: this._heartbeatTimes.join(',')})
          this._heartbeatTimes = [];
      }
  }

  /**
   * 开启心跳
   * @returns {number} 心跳计时器intervalID
   * @private
   */
  _beginHeartbeat() {
    const requestTheHeartbeat = 'requestTheheartbeat';
    let beat = 0;
    this._clearSensorsReportTimer(); //开始心跳前重置定时器前
    let func = () => {
      if (beat) {
        clearTimeout(beat);
      }
      let now = new Date().getTime();
      if (now - this._heartbeatTime > 5 * 1000) {
        // 超过一分半中认为重连 - 改为超过5秒认为重连
        this.reconnect();

        // ws断开重连时停止神策上报，如果当前有记录值，则立即上报一次
        this._clearSensorsReportTimer()
        return;
      }

      if (this.isConnected()) {
        // 记录心跳开始时间
        this._time('ping');
        this.sendMsg(
          JSON.stringify({
            action: requestTheHeartbeat,
            id: requestTheHeartbeat + '|ping|' + new Date().getTime()
          })
        );

        beat = setTimeout(func, this._connectTimeoutSec);
      }else {
        // ws断开时停止神策上报，如果当前有记录值，则立即上报一次
        this._clearSensorsReportTimer()
      }
    };
    func();
    // 当开始心跳时开启神策上报
    this.sensorsReport = setInterval(() => {
      sEvent('ws_response_time', this._websocketUrl, {ext: this._heartbeatTimes.join(',')})
      this._heartbeatTimes = [];
    } , this._sensorsReportInterval)

  }

  /**
   * 执行指定钩子
   * @param lifeCycle 声明周期
   * @param extra 可能需要的参数
   * @private
   */
  _executeCustomLifeCycleCallback(lifeCycle: string, extra?: any) {
    if (WEBSOCKET_PROVIDER_ENUM.hasOwnProperty(lifeCycle)) {
      this._customLifeCycleCallbacks[lifeCycle].forEach(cb => {
        cb(...extra);
      });
    }
  }

  /**
   * 返回连接是否正常
   * @returns {boolean}
   */
  isConnected() {
    return this._connection && this._connection.readyState === 1;
  }

  isConnecting() {
    return this._connection && this._connection.readyState === 0;
  }

  /**
   * 计时
   * @param label
   * @private
   */
  _time(label: string): void {
    this.timeBoard[label] = Date.now();
  }

  /**
   * 计时结束
   * @param label
   * @private
   */
  _timeEnd(label: string): number {
    if (this.timeBoard[label]) {
      return Date.now() - this.timeBoard[label];
    }

    return 0;
  }

  /**
   * 更新记录
   * @param key
   * @param value
   * @private
   */
  _updateSocketRecord(key: string, value: any): void {
    let record = this.socketScoreBoard[this.currentSocketIndex];
    if (record.hasOwnProperty(key)) {
      if (['errorCount'].indexOf(key) > -1) {
        record[key] = Number(record[key]) + value;
        this._saveSocketRecord(true);
      } else {
        record[key] = value;
      }
    }
  }

  /**
   * 初始化一个积分条目
   * @private
   */
  _insertSocketRecord(name: string, url: string): void {
    if (this.currentSocketIndex < 0) {
      return;
    }
    this.socketScoreBoard[this.currentSocketIndex] = {
      name: name,
      url: url,
      score: 0,
      errorCount: 0,
      avgSpeed: 0,
      time: Date.now(),
      connectTime: 0,
      messageLength: 0,
      messageTime: 0
    };
  }

  /**
   * 计算分数并保存到localStorage
   * @private
   */
  _saveSocketRecord(errorFlag: boolean = false): void {
    let record = this.socketScoreBoard[this.currentSocketIndex];
    if (!record['connectTime']) {
      record['score'] = -1; // 特殊标记一下
    } else {
      record['score'] = record['avgSpeed'] / (record['connectTime'] * (record['errorCount'] + 1));
    }
    if (errorFlag) {
      // 如果是错误上报，要把信息都清掉
      record['score'] = -1;
      record['connectTime'] = 0;
      record['messageLength'] = 0;
      record['messageTime'] = 0;
    }
    localStorage.setItem('weex::bbSocket::score', JSON.stringify(this.socketScoreBoard));
  }

  /**
   * 连接
   */
  connect() {
    if (this.isConnected()) {
      console.warn('websocket has already connected!');
      return;
    }

    // BEFORE_OPEN 执行位置
    this._executeCustomLifeCycleCallback(WEBSOCKET_PROVIDER_ENUM.BEFORE_OPEN);
    this._getUrl().then(url=>{
      console.log('url', url)

      if (this.needReport) {
        this._time('connect');
      }

      // let newConnection = new WebSocket(url, generateRandomString(32));
      let sec_protocol = getTokenData(url);
      sec_protocol = removeTailRecursive(sec_protocol, '=');

      let newConnection = new WebSocket(url, sec_protocol);
      // let newConnection = new WebSocket(url);
      // 该定时器会在onopen时清除，如果定时器顺利执行，则认为连接超时
      // 默认超时时间为5秒
      setTimeout(() => {
        if (!this.isConnected()) {
          this._reconnectFlag = false;
          this.reconnect(true);
        }
      }, this._connectTimeoutSec);
      /**
       * 连接打开
       * 将执行队列中的任务执行掉
       */
      newConnection.onopen = () => {
        reconnectCount = 0;
        if (this._reconnectFlag) {
          clearTimeout();
        }
        if (this.needReport) {
          // 记录连接所用时间
          let time = this._timeEnd('connect');
          this._updateSocketRecord('connectTime', time);
        }
        this._executeCustomLifeCycleCallback(WEBSOCKET_PROVIDER_ENUM.ON_OPEN);
        // this._debug('websocket is connected');
        // 执行订阅
        setInterval(() => {
          if(this._executeQueue.length > 0){
            let msg = this._executeQueue.pop();
            this.sendMsg(msg, true);
          }
        },100)
        // while (this._executeQueue.length) {
        // }

        if (this._reconnectFlag) {
          // 重连时用于恢复之前的订阅状态
          for (let oldMsg in this._subscribeActionRecords) {
            if (this._subscribeActionRecords.hasOwnProperty(oldMsg)) {
              this.sendMsg(this._subscribeActionRecords[oldMsg]);
            }
          }
        }

        this._beginHeartbeat();

        this._reconnectFlag = false;
      };

      /**
       * 连接错误时考虑重试
       * @param evt
       */
      newConnection.onerror = (evt: Event) => {
        // this._debug('websocket error', evt);
        this._executeCustomLifeCycleCallback(WEBSOCKET_PROVIDER_ENUM.ON_ERROR, evt);
        // 尝试重连
        this._reconnectFlag = true;
        this.reconnect(true);
      };

      /**
       * 接受到服务器的响应数据时
       * @param evt
       */
      newConnection.onmessage = (evt: MessageEvent) => {
        // 处理一下压缩
        this._executeCustomLifeCycleCallback(WEBSOCKET_PROVIDER_ENUM.ON_MESSAGE, evt);

        let rawMessage = evt.data;

        if (this.socketCompress) {
          this._inflateMessage(rawMessage);
        } else {
          this._dispatchMessage(rawMessage);
        }
      };

      /**
       * 连接被服务器关闭时
       * @param evt
       */
      newConnection.onclose = (evt: CloseEvent) => {
        this._executeCustomLifeCycleCallback(WEBSOCKET_PROVIDER_ENUM.ON_CLOSE, evt);
        if (evt?.code === CONNECTION_ERROR_CODE.E1007) {
          this.msgBoxE1007();
        } else {
          // 尝试重连
          this.reconnect();
        }
      };

      this._connection = newConnection;

    });

  }

  /**
   * 触发1007错误时的提醒
   * @param err
   */
  msgBoxE1007() {
    this.showErr1007 = true;
    let theme = LocalCookie.get(CookieKey.GLOBAL_THEME) || 'black';
    let cusClass = theme == 'white' ? 'big-icon-confirm single' : 'big-icon-confirm single black';
    let i18n = require('../config/request.i18n.conf')();
    MessageBox.alert(`<div class="forbidden-content center">${i18n.wsErr1007}</div>`, '', {
      confirmButtonText: i18n.resetBtn,
      dangerouslyUseHTMLString: true,
      customClass: cusClass,
      type: 'warning',
      title: '',
      center: true,
      iconClass: 'iconfont icon-tishi',
      closeOnClickModal: false,
      closeOnPressEscape: false,
      showCancelButton: false
    }).then(() => {
      this.showErr1007 = false;
      this.reconnect();
    });
  }

  /**
   * 解压缩信息
   * @param rawMessage
   * @private
   */
  _inflateMessage(rawMessage: any) {
    if (rawMessage instanceof Blob) {
      let reader = new FileReader();
      let context = this;
      reader.addEventListener('loadend', function() {
        // $flow-disable-line
        let decodeMessage = inflate(reader.result, { to: 'string' });
        context._dispatchMessage(decodeMessage);
      });

      reader.onerror = function(e) {
        console.log('+++++++', e);
      };

      reader.readAsArrayBuffer(rawMessage);
    } else {
      this._dispatchMessage(rawMessage);
    }
  }

  /**
   * 打印信息
   * @param args
   * @private
   */
  _debug(...args: any[]) {
    if (this._option.debug) {
      // console.log.apply(console, [`trade says> ${getCurrentTime()}`, ...args]);
    }
  }

  /**
   * 预处理并分发消息
   * @param rawMessage
   * @private
   */
  _dispatchMessage(rawMessage: any) {
    try {
      let jsonData = JSON.parse(String(rawMessage));
      let channel = jsonData.action;
      if (channel === 'requestTheheartbeat') {
        this._heartbeatTime = new Date().getTime();

        // 记录心跳用时
        let time = this._timeEnd('ping');
        this._heartbeatTimes.push(time);
      }
      let debug = localStorage.getItem('bb::socket::debug');
      if (debug) {
        if (debug === 'all') {
          console.log(rawMessage);
        } else {
          if (channel === debug) {
            console.log(rawMessage);
          }
        }
      }

      if (jsonData.hasOwnProperty('action')) {
        if (this.needReport) {
          if (!this.messageEnd) {
            this.messageLength += String(rawMessage).length;
            if (jsonData.action === 'klineInit') {
              let time = this._timeEnd('message');
              this._updateSocketRecord('messageLength', this.messageLength);
              this._updateSocketRecord('messageTime', time);
              this._updateSocketRecord('avgSpeed', this.messageLength / time);
              this.messageEnd = true;
              this._saveSocketRecord();
            }
          }
        }
      }

      this._notify(channel, jsonData);
    } catch (e) {
      this._debug('data is not json:', rawMessage);
    }
  }

  /**
   * 对外部提供重连方法
   * @param isError
   */
  reconnect(isError?: boolean) {
    if (this.showErr1007) return;
    if (this.isConnected() || this._reconnectFlag) {
      this._debug('isError1', isError);
      this._debug('this._reconnectFlag1', this._reconnectFlag);
      // 手动重连需要一个标志位
      // 心跳机制导致的重连如果遇到了连接未断的情况，应当向外报错，属于连接内服务器不向下推送数据，可能需要告知用户
      return;
    }
    this._debug('isError2', isError);
    this._debug('this._reconnectFlag2', this._reconnectFlag);
    this._executeCustomLifeCycleCallback(WEBSOCKET_PROVIDER_ENUM.BEFORE_RECONNECT);

    this._reconnectFlag = true;

    if (reconnectCount++ >= 50) {
      // 重连次数过多
      this._reconnectFlag = false;
      clearTimeout(this._reconnectTimer);
      window.location.reload();
    }
    if (this._reconnectTimer) {
      clearTimeout(this._reconnectTimer);
    }

    if (this.needReport && isError) {
      // 记录通道的重连
      this._updateSocketRecord('errorCount', 1);
    }

    // 再次连接
    // console.log('reconnectCount', reconnectCount)
    // console.log('getWSTimoutMilliSecs(reconnectCount)', getWSTimoutMilliSecs(reconnectCount))
    // this._reconnectTimer = setTimeout(func, this._connectTimeoutSec)
    this._reconnectBeginTime = new Date().getTime();
    this._reconnectTimer = setTimeout(() => {
      this.connect();
      this._reconnectEndTime = new Date().getTime();
      // console.log(reconnectCount + 'times', this._reconnectBeginTime, this._reconnectEndTime, (this._reconnectEndTime - this._reconnectBeginTime) / 1000)
    }, getWSTimoutMilliSecs(reconnectCount));
  }

  /**
   * 关闭连接
   * @param forced 是否强制，如果强制关闭则不执行后置操作
   */
  close(forced?: boolean) {
    this._debug('close', forced ? 'forced' : 'normal');
    // 清空执行队列
    this._executeQueue.length = 0;

    // 清空订阅队列
    this._listeners = {};

    // BEFORE_CLOSE 钩子执行位置 回调是否需要参数
    this._executeCustomLifeCycleCallback(WEBSOCKET_PROVIDER_ENUM.BEFORE_CLOSE);

    // 清空定时器
    (this._reconnectTimer && clearTimeout(this._reconnectTimer)) || (this._reconnectTimer = null);

    this._connection.onopen = () => {};
    this._connection.onclose = () => {};
    this._connection.onerror = () => {};
    this._connection.onmessage = () => {};

    this._connection.close();
  }

  /**
   * 注册监听函数
   * @param channel 需要订阅的信息
   * @param cb 收到相应信息的回调函数(status是用来通知监听函数是否需要启用后备方案，data是实际的数据)
   * @returns {number} 监听者的ID，实际上是监听者队列的index，方便从数组中splice
   */
  registerListener(channel: string, cb: Function): number {
    // 增加频道的监听者，已经有的频道需要添加，没有的频道则要初始化监听者数组
    let listenerIndex = 0;
    if (this._listeners[channel]) {
      listenerIndex = this._listeners[channel].push(cb) - 1;
    } else {
      this._listeners[channel] = [cb];
    }
    return listenerIndex;
  }

  /**
   * 取消注册
   * @param channel
   * @param index 要取消的监听函数ID
   */
  unregisterListener(channel: string, index: number): void {
    if (this._listeners[channel]) {
      this._listeners[channel].splice(index, 1);
    }
  }

  /**
   * 如果websocket连接的情况下发送消息，如果给定字符串，provider将不会记录订阅状态，如果给定对象，将根据action来记录订阅状态
   * @param msg 要发送的消息（一般是格式化后的json串）
   * @param flag 如果是来自于心跳重连的发送消息，不需要尝试重连
   * @private
   */
  sendMsg(msg: string | SubscribeRawMessage, flag: boolean = true) {
    let sendMsg: string = '';
    this._debug('sendMsg', msg);
    if (typeof msg === 'string') {
      // 如果是字符串的话就不考虑记录状态了
      sendMsg = String(msg);
    } else if (typeof msg === 'object') {
      sendMsg = JSON.stringify(msg);
      // 尝试根据action字段记录订阅状态
      if (msg.hasOwnProperty('action')) {
        this._subscribeActionRecords[msg.action] = sendMsg;
      }
    } else {
      return;
    }
    if (this.isConnected()) {
      if (this.needReport) {
        if (!this.messageBegin) {
          this._time('message');
          this.messageBegin = true;
        }
      }
      this._connection.send(sendMsg);
      // 过滤心跳消息并神策上报
      if(JSON.parse(sendMsg)?.action !== 'requestTheheartbeat'){
        sEvent('ws_trade_content', this._websocketUrl, {ext: sendMsg})
      }
    } else {
      // setTimeout(() => {
        this._executeQueue.push(sendMsg);
      // }, 3000)
      if (!flag) {
        // 主动重连
        this.connect();
      }
    }
  }

  /**
   * 外部将自定义的操作绑定到websocket的声明周期上
   * @param lifeCycle
   * @param callback
   */
  on(lifeCycle: string, callback: WebsocketLifeCycleCallback) {
    // 显然的，如果在连接后才绑定BEFORE_OPEN和ON_OPEN是没法正常执行的
    if (WEBSOCKET_PROVIDER_ENUM.hasOwnProperty(lifeCycle) && typeof callback === 'function') {
      this._customLifeCycleCallbacks[lifeCycle].push(callback);
    }
  }

  static install(Vue: any, options: any) {
    // 获取链接的真实地址
    getStreamWSUrl().then(res=>{
      Vue.prototype.$websocket = new WebsocketProvider(res);
      Vue.prototype.$websocketProviderEnmu = WEBSOCKET_PROVIDER_ENUM;
      console.log('install', res);
    });
  }
}

// provide提供的可绑定的生命周期
export const WEBSOCKET_PROVIDER_ENUM = {
  BEFORE_OPEN: 'BEFORE_OPEN', // 在connect方法中，new Websocket之前调用
  ON_OPEN: 'ON_OPEN', // websocket.onopen的一开始
  ON_MESSAGE: 'ON_MESSAGE', // websocket.onmessage的一开始
  ON_ERROR: 'ON_ERROR', // websocket.onerror的一开始
  BEFORE_CLOSE: 'BEFORE_CLOSE', // 在close方法中，websocket.close之前调用
  ON_CLOSE: 'ON_CLOSE', // websocket.onclose的一开始
  BEFORE_RECONNECT: 'BEFORE_RECONNECT' // reconnect方法中在connect执行之前执行
};
