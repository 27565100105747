/***
 * 对用户信息进行封装
 */

export const formatUserInfo  = (result)=>{
    
    let { userInfo, settings, loginName } = result
    let newUserInfo = {
     loginName: loginName || '',
     phone: settings.mbf === 1 ? userInfo.mobile : '',
     email: settings.ebf === 1 ? userInfo.email : '',
     bindPhone: Boolean(settings.mbf),
     bindEmail: Boolean(settings.ebf),
     bindGoogle: Boolean(settings.gbf),
     //是否设置了资金密码
     isFund: Boolean(settings.tbf),
     // 资金密码 验证规则 1 2 3 交易时长类型,交易时输入资金密码设置2:每次输入;3：每24小时输入;1：不输入;默认为1', (已废弃)
     fundValue: settings.tpi,
     // 是否可以设置资金密码
     fundDisabled: Boolean(settings.tpl),
     antiPhishingCode: userInfo.antiPhishingCode,
     hasRealValidate: settings.kf == 2,
     // 是否开通otc 1表示是,0表示否，默认为0
     openOtc: Boolean(settings.oo),
     //是否开通合约 1表示是,0表示否，默认为0
     openContract:Boolean(settings.oc),
     //KYC认证状态 0:无认证 1:一级 2:高级认证
     identityLevel: settings.kf,
     // 开启BFT抵扣手续费  0 否 1 是 
     openGtFees: Boolean(settings.ob),
     realName: userInfo.realName,
     emailEnable: Boolean(settings.eef), // 1启用0停用
     phoneEnable: Boolean(settings.mef), // 1启用0停用
     googleEnable: Boolean(settings.gef), // 1启用0停用
     newDeviceFlag:Boolean(settings.ndt), // 是否是新设备
     canWithDraw: settings.cc == 1,//是否可以提币
   }
 return Object.assign({},userInfo,newUserInfo) ;
}